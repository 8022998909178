
import React, {useEffect, useState} from 'react'
import GetFetch from '../helper/GetFetch';

export const FacturaElectronica = () => {

    const [stadoForm, setStadoForm] = useState(0);

    useEffect(() => {
        let datos = localStorage.getItem('dataUser');
        const dataAlumno = JSON.parse(datos);
        console.log(dataAlumno.carnet);
        const data = new FormData();
        data.append('carnet', dataAlumno.carnet);
        GetFetch.dteStadoFormulario(data).then(datos=>{
            
            if (datos.result.length > 0) {
                setStadoForm(datos.result[0].estado);                
            }
        })
    }, [])
    

    

  return (
    <>
    {
        stadoForm === 0 
            ?
            <div className='border border-primary m-4 p-4'>
            <p>Formulario de actualización de datos para factura electrónica</p>
            <a href='https://www.esen.edu.sv/ext/dte/index' target='_blank' className='btn btn-button btn-block btn-info'><i class="fa fa-table" aria-hidden="true"></i> CLICK AQUÍ </a>
        </div>
        : null
    }

    </>
  )
}


