import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import dataContext from "./context/User";
import Login from "./componentes/Login";
import Home from "./componentes/Home";
import Notas from "./componentes/Notas";
import Talonario from "./componentes/Talonarios";
import Solvencia from "./componentes/Solvencia";
import FechasVencimiento from "./componentes/FechasVencimiento";
import Saldos from "./componentes/Saldos";
import SalasColectivas from "./componentes/SalasColectivas";
import PrestamosActivos from "./componentes/PrestamosActivos";
import BiblioCatalogo from "./componentes/BiblioCatalogo";
import Erecursos from "./componentes/Erecursos";
import GuiasTematicas from "./componentes/GuiasTematicas";
import Inicio from './componentes/Inicio';
import Directorio from './componentes/Directorio';
import Correo from './componentes/Correo';
import Eventos from './componentes/Eventos';
import EventoDetalle from './componentes/EventoDetalle';
import Notificaciones from './componentes/Notificaciones';
import ControlAccesoCovid19 from './componentes/ControlAccesoCovid19';
import HorasSociales from './componentes/HorasSociales';
import Cum from './componentes/Cum';
import PracticasProfesionales from "./componentes/PracticasProfesionales";
import Toefl from "./componentes/Toefl";
import BiblioCargosMoras from "./componentes/BiblioCargosMoras";
import PasantiasAlumnos from "./componentes/PasantiasAlumnos";
import PasantiasCV from "./componentes/PasantiasCV";
import PasantiasPF from "./componentes/PasantiasPF";
import PasantiasTips from "./componentes/PasantiasTips";
import PasantiasAlumnosApply from "./componentes/PasantiasAlumnosApply";
import Canchas from './componentes/Canchas';
import CanchaFutbol from "./componentes/CanchaFutlbol";
import CanchaBasketball from "./componentes/CanchaBasketball";
import CanchaVoleibol from "./componentes/CanchaVoleibol";
import CanchaFootballSala from "./componentes/CanchaFootballSala";
import CanchaPP1 from "./componentes/CanchaPP1";
import CanchaPP2 from "./componentes/CanchaPP2";
import ManualEstilo from "./componentes/ManualEstilo";

import AlertMenu from './admin/AlertMenu';
import Push from './admin/Push';
import PushEconomia from './admin/PushEconomia';
import PushDerecho from './admin/PushDerecho';
import PushIng from './admin/PushIng';
import PushIngSoft from "./admin/PushIngSoft";
import PushGeneraciones from "./admin/PushGeneraciones";
import PushAdmin from './admin/PushAdmin';
import PushProfesores from './admin/PushProfesores';
import PushAdminEsen from './admin/PushAdminEsen';
import AlumnosNotas from './admin/AlumnosNotas';
import AlumnosSolvencias from './admin/AlumnosSolvencia';
import AlumnosSaldo from './admin/AlumnosSaldo';
import EventosAdmin from './admin/Eventos';
import EventosForm from './admin/EventosForm';
import AlumnosCIC19 from './admin/AlumnosCIC19';
import RedesSociales from './componentes/RedesSociales';
import PassChange from './admin/PassChange';
import AlumnosHorasSociales from './admin/AlumnosHorasSociales';
import AlumnosCUM from './admin/AlumnosCUM';
import AlumnosMateriasInscritas from './admin/AlumnosMateriasInscritas';
import AlumnosPracticasProfesionales from "./admin/AlumnosPracticasProfesionales";
import AlumnosToefl from "./admin/AlumnosToefl";
import CargosMorasCC from "./admin/CargosMorasCC";
import TalonariosAdmin from "./admin/TalonarioAdmin";
import Pasantias from "./admin/Pasantias";
import PasantiasAll from "./admin/PasantiasAll";
import PasantiasDetalle from "./componentes/PasantiasDetalle";
import PasantiasApply from "./admin/PasantiasApply";
import PasantiasAllOFF from "./admin/PasantiasAllOFF";
import PasantiasAlumnosB from "./admin/PasantiasAlumnosB";
import CanchasAdmin from "./admin/CanchasAdmin";
import CanchasReservas from './admin/CanchasReservas';
import CanchaFootballAdmin from "./admin/CanchaFootballAdmin";
import CanchaBasketballAdmin from "./admin/CanchaBasketballAdmin";
import CanchaFootballSalaAdmin from "./admin/CanchaFootballSalaAdmin";
import CanchaVoleibolAdmin from "./admin/CanchaVoleibolAdmin";
import CanchasConsultasAdmin from "./admin/CanchasConsultasAdmin";
import Stadistica from "./admin/Stadistica";
import HelpDesk from "./admin/HelpDesk";


function Main() {
  return (
    <dataContext.Provider value={dataContext}>
      <BrowserRouter>
        <Route exact path="/" component={Login} />
        <Route exact path="/home" component={Home} />        
        <Route exact path="/notas" component={Notas} />        
        <Route exact path="/talonario" component={Talonario} />        
        <Route exact path="/solvencia" component={Solvencia} />        
        <Route exact path="/fechaspago" component={FechasVencimiento} />        
        <Route exact path="/saldo" component={Saldos} />        
        <Route exact path="/salas" component={SalasColectivas} />        
        <Route exact path="/prestamos" component={PrestamosActivos} />        
        <Route exact path="/catalogo" component={BiblioCatalogo} />        
        <Route exact path="/erecursos" component={Erecursos} />        
        <Route exact path="/tematicas" component={GuiasTematicas} />        
        <Route exact path="/inicio" component={Inicio} />        
        <Route exact path="/directorio" component={Directorio} />        
        <Route exact path="/correo" component={Correo} />        
        <Route exact path="/socialmedia" component={RedesSociales} />        
        <Route exact path="/eventos" component={Eventos} />      
        <Route exact path="/notificaciones" component={Notificaciones} />    
        <Route exact path="/cic" component={ControlAccesoCovid19} />    
        <Route exact path="/horassociales" component={HorasSociales} />    
        <Route exact path="/cum" component={Cum} />    
        <Route exact path="/practicasprofesionales" component={PracticasProfesionales} />    
        <Route exact path="/toefl" component={Toefl} />    
        <Route exact path="/bibliocargomoras" component={BiblioCargosMoras} />    
        <Route exact path="/finanbibliocargomoras" component={BiblioCargosMoras} />    
        <Route exact path="/detalleventos" component={EventoDetalle} />        
        <Route exact path="/alertmenu" component={AlertMenu} />    
        <Route exact path="/pasantiasalumnos" component={PasantiasAlumnos} />    
        <Route exact path="/pasantiadetalle" component={PasantiasDetalle} />    
        <Route exact path="/pasantiascv" component={PasantiasCV} />    
        <Route exact path="/pasantiaspf" component={PasantiasPF} />    
        <Route exact path="/pasantiastips" component={PasantiasTips} />    
        <Route exact path="/pasantiasapplyalumno" component={PasantiasAlumnosApply} />    
        <Route exact path="/canchas" component={Canchas} />    
        <Route exact path="/canchafutbol" component={CanchaFutbol} />    
        <Route exact path="/canchabasketsalafutvolei" component={CanchaBasketball} />    
        <Route exact path="/canchabasvoleibol" component={CanchaVoleibol} />    
        <Route exact path="/canchafootballsala" component={CanchaFootballSala} />    
        <Route exact path="/canchapp1" component={CanchaPP1} />    
        <Route exact path="/canchapp2" component={CanchaPP2} />    
        <Route exact path="/manualestilo" component={ManualEstilo} />    


        <Route exact path="/adminpush" component={Push} />        
        <Route exact path="/pusheconomia" component={PushEconomia} />        
        <Route exact path="/pushderecho" component={PushDerecho} />        
        <Route exact path="/pushing" component={PushIng} />        
        <Route exact path="/pushingsoft" component={PushIngSoft} />        
        <Route exact path="/pushgeneraciones" component={PushGeneraciones} />        
        <Route exact path="/pushadmin" component={PushAdmin} />        
        <Route exact path="/pushprofesores" component={PushProfesores} />        
        <Route exact path="/pushadminesen" component={PushAdminEsen} />        
        <Route exact path="/alumnosnotas" component={AlumnosNotas} />        
        <Route exact path="/alumnossolvencia" component={AlumnosSolvencias} />        
        <Route exact path="/alumnossaldo" component={AlumnosSaldo} />        
        <Route exact path="/admineventoall" component={EventosAdmin} />        
        <Route exact path="/adminevento" component={EventosForm} />        
        <Route exact path="/cicadmin" component={AlumnosCIC19} />  
        <Route exact path="/passchange" component={PassChange} />      
        <Route exact path="/adminhorassociales" component={AlumnosHorasSociales} />      
        <Route exact path="/admincum" component={AlumnosCUM} />      
        <Route exact path="/adminalumnimateriasinscritas" component={AlumnosMateriasInscritas} />      
        <Route exact path="/adminpracticas" component={AlumnosPracticasProfesionales} />      
        <Route exact path="/admintoefl" component={AlumnosToefl} />      
        <Route exact path="/admincargomora" component={CargosMorasCC} />      
        <Route exact path="/admintalonario" component={TalonariosAdmin} />      
        <Route exact path="/pasantias" component={Pasantias} />      
        <Route exact path="/pasantiasalumnosb" component={PasantiasAlumnosB} />      
        <Route exact path="/adminpasantiasall" component={PasantiasAll} />      
        <Route exact path="/adminpasantiasapply" component={PasantiasApply} />      
        <Route exact path="/adminpasantiasalloff" component={PasantiasAllOFF} />      
        <Route exact path="/canchasadmin" component={CanchasAdmin} />      
        <Route exact path="/canchasreservasadmin" component={CanchasReservas} />      
        <Route exact path="/canchafootballadmin" component={CanchaFootballAdmin} />      
        <Route exact path="/canchabasketballadmin" component={CanchaBasketballAdmin} />      
        <Route exact path="/canchafootballsalaadmin" component={CanchaFootballSalaAdmin} />      
        <Route exact path="/canchavoleiboladmin" component={CanchaVoleibolAdmin} />      
        <Route exact path="/canchaconsultasladmin" component={CanchasConsultasAdmin} />      
        <Route exact path="/stati" component={Stadistica} />      
        <Route exact path="/helpdesk" component={HelpDesk} />      
      </BrowserRouter>
    </dataContext.Provider>
  );
}

export default Main;
