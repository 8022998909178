import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
import CarSolvencia from "../element/CarSolvencia";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from './Footer';

const Solvencia = () => {

  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem('dataUser');
  const [solvencia, setSolvencia] = useState({});

  useEffect(() => {

    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;

      let solvencia = GetFetch.solvencia(dataUser.carnet, dataUser.token);
      solvencia.then((dataSolvencia) => {
        setSolvencia(dataSolvencia);
        console.log(dataSolvencia);
      });

      loadMenu();
    } else {
      history.push("/");
    }
  }, [datos, history, dataUser]);


  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4">Solvencia financiera</h2>
        <div className="card">
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <h4 className="text-success">
                      Componente administrativo
                    </h4>
                  </th>
                  <th>
                    <h4 className="text-success">
                      Estado
                    </h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                {solvencia.length > 0
                  ? solvencia.map((element) => {
                      return (
                        <CarSolvencia
                          componente={element.componente}
                          solvencia={element.solvencia}
                        />
                      );
                    })
                  : solvencia.length}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solvencia;
