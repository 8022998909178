import apiURL from '../hooks/useAPI';

const { fetchAPI } = apiURL;

const GetFetch = {};

const requestData = async(url, token) => {
    if (url) {
        try {
            const entryPointLogin = url;
            const optionFetch = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'secret': '5ec4702eff406849a82818cb'
                }
            }
            return await fetch(entryPointLogin, optionFetch)
                .then(result => result.json())
                .then((data) => {
                    if (data.message === 'Insolvente') {
                        localStorage.setItem('solvencia', 'Insolvente');
                    }
                    if (+data.success === 1) {
                        if (data.result) {
                            return data.result
                        } else {
                            return data.data;
                        }
                    } else {
                        return data.result = {};
                    }
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

const requestDataPostBiblio = async(url, tipo, contenido, token, pagina, numRow) => {
    if (url) {
        try {
            const entryPointLogin = url;
            let data = {
                "contenido": `${contenido}`,
                "tipo": +tipo,
                "secret": "5ec4702eff406849a82818cb",
                pagina,
                numRow
            };
            const optionFetch = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }
            return await fetch(entryPointLogin, optionFetch)
                .then(result => result.json())
                .then((data) => {
                    if (+data.success === 1) {
                        return data.result;
                    } else {
                        return data.result = {};
                    }
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

const requestDataPostEventos = async(url, dataForm, token) => {
    if (url) {
        try {
            const entryPointLogin = url;
            return await fetch(entryPointLogin, {
                    method: 'POST',
                    body: dataForm
                })
                .then(result => result.json())
                .then((data) => {
                    return data;
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

const requestDataPostsSatistics = async(url, start, end, token) => {
    if (url) {
        try {
            const entryPointLogin = url;
            return await fetch(entryPointLogin, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify({ start, end })
                })
                .then(result => result.json())
                .then((data) => {
                    return data;
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

const requestDataPostBiblioPass = async(url, data, token) => {
    if (url) {
        try {
            const entryPointLogin = url;
            return await fetch(entryPointLogin, {
                    method: 'POST',
                    body: data
                })
                .then(result => result.json())
                .then((data) => {
                    return data.result;
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

const requestDataPosCovid19 = async(url, token) => {
    if (url) {
        try {
            const entryPointLogin = url;
            const optionFetch = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'secret': '5ec4702eff406849a82818cb'
                }
            }
            return await fetch(entryPointLogin, optionFetch)
                .then(result => result.json())
                .then((data) => {
                    if (+data.success === 1) {
                        return data.result;
                    } else {
                        return data.result = {};
                    }
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

const urlAPI = fetchAPI;
// const urlAPI = 'http://localhost:4000/api/';
// const urlAPILocal = 'http://localhost:4000/api/';

GetFetch.horarios = (carnet, token) => {
    const url = `${urlAPI}registro/horarios/${carnet}`;
    return requestData(url, token);
}

GetFetch.notas = (carnet, token) => {
    const url = `${urlAPI}registro/notasall/${carnet}`;
    return requestData(url, token);
}

GetFetch.notasAdmin = (carnet, token) => {
    const url = `${urlAPI}registro/notasalladmin/${carnet}`;
    return requestData(url, token);
}

GetFetch.datosAlumno = (carnet, token) => {
    const url = `${urlAPI}registro/alumnosdatos/${carnet}`;
    return requestData(url, token);
}

GetFetch.horasSociales = (carnet, token) => {
    const url = `${urlAPI}registro/horassociales/${carnet}`;
    return requestData(url, token);
}

GetFetch.cum = (carnet, token) => {
    const url = `${urlAPI}registro/cum/${carnet}`;
    return requestData(url, token);
}

GetFetch.practicasProfesionales = (carnet, token) => {
    const url = `${urlAPI}registro/practicasprofesionales/${carnet}`;
    return requestData(url, token);
}

GetFetch.toefl = (carnet, token) => {
    const url = `${urlAPI}registro/toefl/${carnet}`;
    return requestData(url, token);
}

GetFetch.talonarios = (carnet, token) => {
    const url = `${urlAPI}pagos/talonarioappweb/${carnet}`;
    return requestData(url, token);
}

GetFetch.solvencia = (carnet, token) => {
    const url = `${urlAPI}solvencia/${carnet}`;
    return requestData(url, token);
}

GetFetch.saldosAlumno = (carnet, token) => {
    const url = `${urlAPI}pagos/saldos/${carnet}`;
    return requestData(url, token);
}

GetFetch.saldosPlanesPagoAlumno = (carnet, token) => {
    const url = `${urlAPI}pagos/planesdepago/${carnet}`;
    return requestData(url, token);
}

GetFetch.fechasVencimiento = (token) => {
    const url = `${urlAPI}pagos/vencimientopagos/all`;
    return requestData(url, token);
}

GetFetch.salasColectivas = (carnet, token) => {
    const url = `${urlAPI}biblio/salascolectivas/all/${carnet}`;
    return requestData(url, token);
}

GetFetch.prestamosActivos = (carnet, token) => {
    const url = `${urlAPI}biblio/${carnet}`;
    return requestData(url, token);
}

GetFetch.catalogo = (tipo, contenido, token, carnet, pagina, numRow) => {
    const url = `${urlAPI}biblio/catalogo/${carnet}`;
    return requestDataPostBiblio(url, tipo, contenido, token, pagina, numRow);
}

GetFetch.adquisiciones = (token) => {
    const url = `${urlAPI}biblio/adquisiones/all`;
    return requestData(url, token);
}

GetFetch.getPass = (data, token) => {
    const url = `${urlAPI}biblio/passcoin`;
    return requestDataPostBiblioPass(url, data, token);
}

GetFetch.getPassUpdate = (data, token) => {
    const url = `${urlAPI}biblio/updatepass`;
    return requestDataPostBiblioPass(url, data, token);
}

GetFetch.getCargosMorasBiblio = (carnet, token) => {
    const url = `${urlAPI}biblio/otroscargos/` + carnet;
    return requestData(url, token);
}

GetFetch.getMorasBiblio = (carnet, token) => {
    const url = `${urlAPI}biblio/moras/` + carnet;
    return requestData(url, token);
}

GetFetch.getMorasTitulosBiblio = (carnet, token) => {
    const url = `${urlAPI}biblio/morastitulos/` + carnet;
    return requestData(url, token);
}

GetFetch.getEventos = (token) => {
    const url = `${urlAPI}evento/`;
    return requestData(url, token);
}

GetFetch.getEventosID = (id, token) => {
    const url = `${urlAPI}evento/` + id;
    return requestData(url, token);
}

GetFetch.setEventos = (dataForm, token) => {
    const url = `${urlAPI}evento/`;
    return requestDataPostEventos(url, dataForm, token);
}
GetFetch.updateEventos = (dataForm, token) => {
    const url = `${urlAPI}evento/update/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.downEventos = (dataForm, token) => {
    const url = `${urlAPI}evento/down/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.cicovid19 = (token, carnet) => {
    const url = `${urlAPI}cic/${carnet}`;
    return requestDataPosCovid19(url, token);
}

GetFetch.cicovid19Permiso = (token, carnet) => {
    const url = `${urlAPI}cic/permiso/${carnet}`;
    return requestDataPosCovid19(url, token);
}

GetFetch.cicovid19PermisoSemanas = (token, carnet) => {
    const url = `${urlAPI}cic/semanas/${carnet}`;
    return requestDataPosCovid19(url, token);
}

GetFetch.setPasantia = (dataForm, token) => {
    const url = `${urlAPI}pasantia/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.sendPasantiaCorreoEmpresaContacto = (dataForm, token) => {
    const url = `${urlAPI}pasantia/sendcorreoempresa/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.sendPasantiaCorreoAlumnoComentarioCV = (dataForm, token) => {
    const url = `${urlAPI}pasantia/sendcorreoalumno/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.setSendCV = (dataForm, token) => {
    const url = `${urlAPI}pasantia/uploadcv/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.setApplyPasantia = (dataForm, token) => {
    const url = `${urlAPI}pasantia/apply/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.removeApplyPasantia = (dataForm, token) => {
    const url = `${urlAPI}pasantia/applyremove/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.updatePasantia = (dataForm, token) => {
    const url = `${urlAPI}pasantia/update/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.updatePasantiaStatus = (dataForm, token) => {
    const url = `${urlAPI}pasantia/updatestatus/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.updatePasantiaStatusSelect = (dataForm, token) => {
    const url = `${urlAPI}pasantia/updatestatusselect/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.eliminarPasantia = (dataForm, token) => {
    const url = `${urlAPI}pasantia/delete/`;
    return requestDataPostEventos(url, dataForm, token);
}

GetFetch.getPasantiaID = (id, token) => {
    const url = `${urlAPI}pasantia/` + id;
    return requestData(url, token);
}

GetFetch.getPasantiaAll = (token) => {
    const url = `${urlAPI}pasantia/`;
    return requestData(url, token);
}

GetFetch.getPasantiaAllOFF = (token) => {
    const url = `${urlAPI}pasantia/pasantiasoff`;
    return requestData(url, token);
}

GetFetch.fileValidarCVPasantia = (data, token) => {
    const url = `${urlAPI}pasantia/filevalidarcv/`;
    return requestDataPostEventos(url, data, token);
}

GetFetch.getApplyAlumno = (data, token) => {
    const url = `${urlAPI}pasantia/applyalumno/`;
    return requestDataPostEventos(url, data, token);
}

GetFetch.getDeportiosEspacios = (fecha, cancha, token) => {
    const url = `${urlAPI}deportivos/espacios_fecha/${fecha}/${cancha}`;
    return requestData(url, token);
}

GetFetch.getDeportiosReservasAlumno = (carnet, token) => {
    const url = `${urlAPI}deportivos/responsable/${carnet}`;
    return requestData(url, token);
}

GetFetch.getDeportiosAllFecha = (data, token) => {
    const url = `${urlAPI}deportivos/reservasadmin/`;
    return requestDataPostEventos(url, data, token);
}

GetFetch.getDeportiosAllFechaGeneral = (data, token) => {
    const url = `${urlAPI}deportivos/reservasadmingeneral/`;
    return requestDataPostEventos(url, data, token);
}

GetFetch.getDeportiosAllFechaApro = (data, token) => {
    const url = `${urlAPI}deportivos/reservasadminapro/`;
    return requestDataPostEventos(url, data, token);
}

GetFetch.getDeportiosParticipante = (carnet, token) => {
    const url = `${urlAPI}registro/${carnet}`;
    return requestData(url, token);
}

GetFetch.setDeportiosEspaciosReserva = (data, token) => {
    const url = `${urlAPI}deportivos/`;
    return requestDataPostEventos(url, data, token);
}

GetFetch.setDeportiosConfirmarReserva = (data, token) => {
    const url = `${urlAPI}deportivos/confirmarreserva`;
    return requestDataPostEventos(url, data, token);
}

GetFetch.setDeportiosCancelarReservaAlumno = (data, token) => {
    const url = `${urlAPI}deportivos/cancelarreservaalumno`;
    return requestDataPostEventos(url, data, token);
}

GetFetch.statisTotal = (start, end, token) => {
    const url = `${urlAPI}audibita/getbitacoratotal`;
    return requestDataPostsSatistics(url, start, end, token);
}

GetFetch.statisTotalConsulta = (start, end, token) => {
    const url = `${urlAPI}audibita/getbitacoratotalgen`;
    return requestDataPostsSatistics(url, start, end, token);
}

GetFetch.statisLast = (start, end, token) => {
    const url = `${urlAPI}audibita/getbitacoraconsultas`;
    return requestDataPostsSatistics(url, start, end, token);
}
GetFetch.dteStadoFormulario = (dataForm, token) => {
    const url = `${urlAPI}finanzas/`;
    return requestDataPostEventos(url, dataForm, token);
}

export default GetFetch