import React from "react";
import apiURL from '../hooks/useAPI';

const Foto = ({ foto, alt, tamanio }) => {    
    const { fotoAPI } = apiURL;
    let Error_Cargar = (e) => {
        let src = `${fotoAPI}static/usrs/nouser.png`;
        // let src = `http://biblioteca.esen.edu.sv/images/usrs/nouser.png`;
        e.target.setAttribute("src", src);
    }
    // let url = `http://biblioteca.esen.edu.sv/images/usrs/${foto}.jpg`;
    let url = `${fotoAPI}static/usrs/${foto}.jpg`;
    return(
        <div className="text-center">
        <img className = { tamanio } src = { url }  alt = { alt } onError={Error_Cargar}/>
        </div>
    )
}

export default Foto